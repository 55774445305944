<template>
  <div>
    <v-container fluid class="px-6 py-6 all">
      <Loading v-if="loading"></Loading>
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row class="container-print">
        <v-col cols="1">
          <v-btn
            :ripple="false"
            class="text-white mt-3 font-weight-bolder"
            small
            color="#c7373a"
            @click="print()"
            >{{ $t("print") }}</v-btn
          >
        </v-col>
      </v-row>
      <div class="dashboard">
        <v-row>
          <v-col md="12" class="card-section z-index-2">
            <v-row>
              <v-col sm="6" class="box" cols="12">
                <v-card class="card-shadow">
                  <v-app-bar
                    color="light"
                    class="border-left-primary head-title box-shadow-none"
                    >{{ total_select.name }}</v-app-bar
                  >
                  <div class="border-print">
                    <v-card-text class="card-padding">
                      <v-row no-gutters>
                        <v-col sm="8">
                          <!-- <p
                            class="text-sm mb-0 text-capitalize font-weight-bold"
                          >{{ $t("Total Clients") }}</p>-->

                          <v-select
                            :items="title_total"
                            v-model="total_select"
                            item-value="table"
                            item-text="name"
                            label
                            class="pt-0 visibility-none"
                            return-object
                            sm="6"
                            @change="get_total()"
                          ></v-select>

                          <h5 class="text-h5 font-weight-bolder mb-0">
                            {{ total }}
                            <!-- <span class="text-danger text-sm font-weight-bolder">-2%</span> -->
                          </h5>
                        </v-col>
                        <v-col sm="4" class="text-end">
                          <v-avatar
                            color="bg-gradient-success"
                            class="shadow"
                            rounded
                          >
                            <v-icon size="20" class="text-white"
                              >fa fa-user-circle</v-icon
                            >
                          </v-avatar>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>
                </v-card>
              </v-col>
              <v-col sm="6" class="box" cols="12">
                <v-card class="card-shadow">
                  <v-app-bar
                    color="light"
                    class="border-left-primary head-title box-shadow-none"
                    >{{ total_select2.name }}</v-app-bar
                  >
                  <div class="border-print">
                    <v-card-text class="card-padding">
                      <v-row no-gutters>
                        <v-col sm="8">
                          <!-- <p
                            class="text-sm mb-0 text-capitalize font-weight-bold"
                          >{{ $t("Total Clients") }}</p>-->
                          <v-select
                            :items="title_total2"
                            v-model="total_select2"
                            item-value="table"
                            item-text="name"
                            label
                            class="pt-0 visibility-none"
                            return-object
                            sm="6"
                            @change="get_total2()"
                          ></v-select>

                          <h5 class="text-h5 font-weight-bolder mb-0">
                            {{ total2 }}
                            <!-- <span class="text-danger text-sm font-weight-bolder">-2%</span> -->
                          </h5>
                        </v-col>
                        <v-col sm="4" class="text-end">
                          <v-avatar
                            color="bg-gradient-success"
                            class="shadow"
                            rounded
                          >
                            <v-icon size="20" class="text-white"
                              >fa fa-user-circle</v-icon
                            >
                          </v-avatar>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="6" class="chart-month" cols="12">
            <v-card class="card-shadow d-grid light">
              <v-app-bar
                color="light"
                class="border-left-primary head-title box-shadow-none"
                >{{ monthly_select.name }} {{ $t("by months") }}</v-app-bar
              >
              <div class="border-print">
                <v-card-text class="card-padding">
                  <div class="bg-custome border-radius-lg py-3 pe-1 mb-6">
                    <div id="salesChartDev" class="chart-area">
                      <canvas :height="170" :id="salesChartID"></canvas>
                    </div>
                  </div>
                  <h6
                    class="
                      ms-2
                      mt-6
                      mb-0
                      visibility-none
                      text-h6
                      font-weight-bold
                    "
                  >
                    <v-select
                      :items="title_months"
                      v-model="monthly_select"
                      item-value="table"
                      item-text="name"
                      label
                      return-object
                      sm="3"
                      @change="get_monthly()"
                    ></v-select>
                    {{ $t("by months") }}
                  </h6>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
          <v-col class="z-index-2 chart-order" lg="6" v-if="data_order_status">
            <v-card class="card-shadow d-grid light" style="height: 100%">
              <v-app-bar
                color="light"
                class="border-left-primary head-title box-shadow-none"
                >{{ $t("Approve And Rejected Order") }}</v-app-bar
              >
              <div class="border-print">
                <div class="card-header-padding visibility-none pb-0">
                  <div class="font-weight-bold text-h6 mb-2">
                    {{ $t("Approve And Rejected Order") }}
                  </div>
                </div>
                <v-card-text class="card-padding">
                  <div
                    id="ordersChartDev"
                    class="chart-area"
                    style="margin-top: 38px"
                  >
                    <canvas height="170" :id="ordersChartID"></canvas>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="box-dount mt-6">
          <v-col lg="6" class="pt-0 z-index-2" v-if="stages_div">
            <v-card class="card-shadow z-index-2 d-grid fix-hight">
              <v-app-bar
                color="light"
                class="border-left-primary head-title box-shadow-none"
                >{{ $t("Applications By") }}
                {{ selected_chart_by.name }}</v-app-bar
              >
              <div class="border-print">
                <v-card-text class="visibility-none card-padding">
                  <div class="pb-0">
                    <h6 class="text-h6 font-weight-bold">
                      {{ $t("Applications By") }}
                      <v-select
                        :items="chart_by"
                        v-model="selected_chart_by"
                        item-value="table"
                        item-text="name"
                        hide-details
                        label
                        return-object
                        sm="6"
                        class="select_by pt-0 mt-0"
                        @change="get_chart_by()"
                      ></v-select>
                    </h6>
                  </div>
                </v-card-text>
                <div class="card-padding fix-hight">
                  <div id="chartbyDev" class="chart">
                    <canvas
                      id="chart_by"
                      class="chart-canvas"
                      height="300"
                    ></canvas>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col lg="6" class="pt-0 z-index-2" v-if="ticket_div">
            <v-card class="card-shadow z-index-2 d-grid fix-hight">
              <v-app-bar
                color="light"
                class="border-left-primary head-title box-shadow-none"
                >{{ $t("Ticket") }}</v-app-bar
              >
              <div class="border-print">
                <v-card-text class="card-padding visibility-none">
                  <div class="pb-0">
                    <h6 class="text-h6 pt-2 font-weight-bold">
                      {{ $t("Ticket") }}
                    </h6>
                  </div>
                </v-card-text>
                <div class="card-padding fix-hight">
                  <div class="chart">
                    <canvas
                      id="ticket-chart"
                      class="chart-canvas"
                      height="300"
                    ></canvas>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <!-- <widget-globe></widget-globe> -->
          </v-col>
        </v-row>
        <!-- new -->
        <v-row class="box-chart">
          <v-col cols="12">
            <v-card class="light card-shadow">
              <v-app-bar
                color="light"
                class="border-left-primary chart_head box-shadow-none pt-3"
              >
                <v-autocomplete
                  :items="applicants"
                  v-model="applicant1"
                  item-value="id"
                  item-text="company"
                  label="Applicant1"
                  sm="6"
                  return-object
                  @change="redraw()"
                ></v-autocomplete>
                <v-autocomplete
                  :items="applicants"
                  v-model="applicant2"
                  item-value="id"
                  item-text="company"
                  label="Applicant2"
                  sm="6"
                  return-object
                  @change="redraw()"
                ></v-autocomplete>
                <v-select
                  class="chart_select"
                  :items="years"
                  v-model="selected_year"
                  label="Year"
                  sm="2"
                  @change="redraw()"
                  :disabled="radioCheck == 1"
                >
                </v-select>
                <v-radio-group
                  class="radio-chart"
                  v-model="radioCheck"
                  row
                  v-on:change="redraw()"
                >
                  <v-radio label="Monthly" value="0"></v-radio>
                  <v-radio label="Yearly" value="1"></v-radio>
                  <v-radio label="Quarterly" value="2"></v-radio>
                </v-radio-group>
                <v-select
                  class="chart_select"
                  :items="total_inquiry"
                  v-model="selected_inquiry"
                  item-value="id"
                  item-text="name"
                  label="Inquiry"
                  return-object
                  sm="2"
                  @change="redraw()"
                >
                </v-select>
              </v-app-bar>
              <div class="border-print">
                <v-card-text class="card-padding">
                  <div id="chartLine">
                    <canvas id="compair" height="100"></canvas>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <!-- endNew -->
      </div>
    </v-container>
  </div>
</template>

<script>
// import { ordersChart } from "@/components/Charts/Chart";
import Chart from "chart.js/auto";
import SalesCountry from "./Widgets/SalesCountry.vue";
import WidgetGlobe from "./Widgets/Globe.vue";
import dashboardService from "../../services/dashboard.service";
import Notify from "../Components/Old/Notify.vue";

import Loading from "../Components/Loading.vue";
export default {
  name: "Dashboard",
  components: {
    SalesCountry,
    Loading,
    Notify,
    WidgetGlobe,
  },
  data: function () {
    return {
      message: {
        msg: null,
        type: null,
      },
      loading: false,
      backgroundColor: [
        // blue

        "#047a6f",
        "#06ab95",
        "#89c8bb",
        "#72aee6",
        "#2271b1",
        // red
        "#f66368",
        "#e65054",
        "#C7373A",
        // green
        "#007017",
        "#17ad37",
        "#48cfad",
      ],
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      total_order: 0,
      total: 0,
      total2: 0,
      pending_order: 0,
      active_cycles: 0,
      data_order_status: true,
      ticket_div: true,
      status_div: true,
      stages_div: true,
      cycles_div: true,
      status_lable: [],
      stages_lable: [],
      cycles_lable: [],
      statuses_order_monthly_lable: [],
      tickets_lable: [],
      chart1_data: [],
      chart1_months: [],
      stages: [],
      data_order_reject: [],
      data_order_approve: [],
      tickets: [],
      statuses: [],
      cycles: [],

      title_total: [
        { name: "Total Applications", table: "total_orders" },
        { name: "Total Applicants", table: "applicants" },
        { name: "Pending Applications", table: "pending_orders" },
        { name: "Rejected Applications", table: "rejected_orders" },
        { name: "Applications in Progress", table: "progress_orders" },
        { name: "Approved Applications", table: "approved_orders" },
        { name: "Cycles", table: "cycles" },
        { name: "Stages", table: "stages" },
        { name: "Tickets", table: "tickets" },
        { name: "Open Tickets", table: "open_tickets" },
        { name: "Closed Tickets", table: "closed_tickets" },
        // ,'Pending Applications','Total Applicants'}]
      ],
      total_select: { name: "Total Applications", table: "total_orders" },

      title_total2: [
        { name: "Total Applications", table: "total_orders" },
        { name: "Total Applicants", table: "applicants" },
        { name: "Pending Applications", table: "pending_orders" },
        { name: "Rejected Applications", table: "rejected_orders" },
        { name: "Progress Applications", table: "progress_orders" },
        { name: "Approved Applications", table: "approved_orders" },
        { name: "Cycles", table: "cycles" },
        { name: "Stages", table: "stages" },
        { name: "Tickets", table: "tickets" },
        { name: "Open Tickets", table: "open_tickets" },
        { name: "Closed Tickets", table: "closed_tickets" },
        // ,'Pending Applications','Total Applicants'}]
      ],
      total_select2: { name: "Total Applications", table: "total_orders" },

      title_months: [
        { name: "Total Applications", table: "orders" },
        { name: "Total Applicants", table: "applicants" },
      ],
      monthly_select: { name: "Total Applicants", table: "applicants" },

      chart_by: [
        { name: "Status", table: "statuses" },
        { name: "Cycle", table: "cycles" },
        { name: "Stage", table: "stages" },
      ],
      selected_chart_by: { name: "Statuses", table: "statuses" },
      total_inquiry: [],
      applicants: [],
      applicant1: "",
      applicant2: "",
      selected_inquiry: null,
      labels: [],
      datasets: [],
      chart_data: [],
      radioCheck: "0",
      years: [],
      selected_year: null,
    };
  },
  methods: {
    order_status() {
      var self = this;
      new Chart(document.getElementById("status-chart").getContext("2d"), {
        type: "bar",
        data: {
          labels: self.status_lable,
          datasets: [
            {
              label: self.$i18n.t("status"),
              weight: 5,
              borderWidth: 0,
              borderRadius: 4,
              backgroundColor: "#3A416F",
              data: self.statuses,
              fill: false,
            },
          ],
        },
        options: {
          indexAxis: "y",
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#fff",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: true,
              },
              ticks: {
                display: true,
                color: "#fff",
                padding: 10,
              },
            },
          },
        },
      });
    },
    order_cycles() {
      var self = this;
      new Chart(document.getElementById("cycle-chart").getContext("2d"), {
        type: "doughnut",
        data: {
          labels: self.cycles_lable,
          datasets: [
            {
              label: self.$i18n.t("Cycles"),
              weight: 9,
              cutout: 60,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 0,
              backgroundColor: self.backgroundColor,
              data: self.cycles,
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      });
    },
    order_stages() {
      var self = this;
      new Chart(document.getElementById("chart_by").getContext("2d"), {
        type: "pie",
        data: {
          labels: self.stages_lable,
          datasets: [
            {
              label: this.$i18n.t("Stages"),
              weight: 9,
              cutout: 0,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 0,
              backgroundColor: self.backgroundColor,
              data: self.stages,
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: "left",
              textAlign: "center",
              labels: {
                usePointStyle: true,
              },
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      });
    },
    get_tickets() {
      var self = this;
      new Chart(document.getElementById("ticket-chart").getContext("2d"), {
        type: "pie",
        data: {
          labels: self.tickets_lable,
          datasets: [
            {
              label: self.$i18n.t("Ticket"),
              weight: 9,
              cutout: 0,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 0,
              backgroundColor: self.backgroundColor,
              data: self.tickets,
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: "left",
              textAlign: "center",
              labels: {
                usePointStyle: true,
              },
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      });
    },

    monthly_order() {
      var self = this;
      // chart.destroy();
      var chart1 = new Chart(
        document.getElementById(self.salesChartID).getContext("2d"),
        {
          type: "bar",
          data: {
            labels: self.chart1_months,
            datasets: [
              {
                label: self.$i18n.t(self.monthly_select.name),
                tension: 0.4,
                borderWidth: 0,
                borderRadius: 4,
                borderSkipped: false,
                backgroundColor: "#c7373a",
                data: self.chart1_data,
                maxBarThickness: 6,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: 500,
                  beginAtZero: true,
                  padding: 15,
                  font: {
                    size: 14,
                    family: "Open Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                  color: "#c7373a",
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#c7373a",
                },
              },
            },
          },
        }
      );
    },
    order_status_monthly() {
      var self = this;
      self.data_order_status = true;
      const ctx = document.getElementById(self.ordersChartID).getContext("2d");

      var gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, "rgba(23,173,55,0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke1.addColorStop(0, "rgba(23,173,55,0)"); //purple colors

      var gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
      gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke2.addColorStop(0, "rgba(20,23,39,0)"); //purple colors

      new Chart(ctx, {
        type: "line",
        data: {
          labels: self.statuses_order_monthly_lable,
          datasets: [
            {
              label: "Approved",
              tension: 0.4,
              pointRadius: 0,
              borderColor: "#047a6f",
              borderWidth: 3,
              backgroundColor: gradientStroke1,
              fill: true,
              data: self.data_order_approve,
              maxBarThickness: 6,
            },
            {
              label: "Rejected",
              tension: 0.4,
              pointRadius: 0,
              borderColor: "#c7373a",
              borderWidth: 3,
              backgroundColor: gradientStroke2,
              fill: true,
              data: self.data_order_reject,
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                beginAtZero: true,
                padding: 10,
                color: "#000",
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#000",
                padding: 20,
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    },
    get_status_months() {
      var self = this;
      let formData = new FormData();
      formData.append("type", self.monthly_select.table);
      return dashboardService.get_monthly_status(formData).then(
        (response) => {
          console.log(response);
          self.statuses_order_monthly_lable = [];
          self.data_order_approve = [];
          self.data_order_reject = [];
          // if (order_statuses_monthly.length > 0) {
          response.data.map(function (item) {
            self.statuses_order_monthly_lable.push(item.lables);
            self.data_order_reject.push(item.rejected);
            self.data_order_approve.push(item.approved);
          });
          // }

          document.getElementById("ordersChart").remove();
          document
            .getElementById("ordersChartDev")
            .insertAdjacentHTML(
              "beforeend",
              '<canvas :height="170" id="ordersChart"></canvas>'
            );
          self.order_status_monthly();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // get_data() {
    //   var self = this;
    //   // self.loading = false;
    //   return dashboardService.get_data().then(
    //     (response) => {
    //       console.log(response);
    //       this.total_order = response.data.total_order;
    //       this.total_users = response.data.total_users;
    //       this.pending_order = response.data.pending_order;
    //       this.active_cycles = response.data.active_cycles;

    //       // response.data.monthly_orders.forEach((element) => {
    //       //   self.chart1_data.push(element.count);
    //       //   self.chart1_months.push(element.months);
    //       // });
    //       // self.monthly_order();

    //       // if (response.data.statuses_order) {
    //       //   var order_statuses_monthly = response.data.statuses_order;
    //       //   if (order_statuses_monthly.length > 0) {
    //       //     order_statuses_monthly.map(function (item) {
    //       //       self.statuses_order_monthly_lable.push(item.lables);
    //       //       self.data_order_reject.push(item.rejected);
    //       //       self.data_order_approve.push(item.approved);
    //       //     });
    //       //     self.order_status_monthly();
    //       //   }
    //       // } else {
    //       //   self.data_order_status = false;
    //       // }

    //       // if (response.data.statuses) {
    //       //   var statuses = response.data.statuses;
    //       //   if (statuses.length > 0) {
    //       //     statuses.map(function (item) {
    //       //       self.status_lable.push(item.lables);
    //       //       self.statuses.push(item.count);
    //       //     });
    //       //     self.order_status();
    //       //   }
    //       // } else {
    //       //   self.status_div = false;
    //       // }

    //       // if (response.data.stages) {
    //       //   var stages = response.data.stages;
    //       //   if (stages.length > 0) {
    //       //     stages.map(function (item) {
    //       //       self.stages_lable.push(item.lables);
    //       //       self.stages.push(item.count);
    //       //     });
    //       //     self.order_stages();
    //       //   }
    //       // } else {
    //       //   self.stages_div = false;
    //       // }

    //       // if (response.data.cycles) {
    //       //   var cycles = response.data.cycles;
    //       //   if (cycles.length > 0) {
    //       //     cycles.map(function (item) {
    //       //       self.cycles_lable.push(item.lables);
    //       //       self.cycles.push(item.count);
    //       //     });
    //       //     self.order_cycles();
    //       //   }
    //       // } else {
    //       //   self.cycles_div = false;
    //       // }

    //       // if (response.data.tickets) {
    //       //   var tickets_data = response.data.tickets;
    //       //   self.tickets_lable = tickets_data.lables;
    //       //   self.tickets = tickets_data.data;
    //       //   self.get_tickets();
    //       // } else {
    //       //   self.ticket_div = false;
    //       // }
    //       // self.loading = false;
    //     },
    //     (error) => {
    //       console.log(error);
    //       self.loading = false;
    //       self.message = {
    //         msg: self.$i18n.t("general.there is problem"),
    //         type: "Danger",
    //       };
    //     }
    //   );
    // },
    get_total() {
      var self = this;
      let formData = new FormData();
      formData.append("type", self.total_select.table);
      return dashboardService.get_total(formData).then(
        (response) => {
          self.total = response.data;
        },
        (error) => {}
      );
    },
    get_total2() {
      var self = this;
      let formData = new FormData();
      formData.append("type", self.total_select2.table);
      return dashboardService.get_total(formData).then(
        (response) => {
          self.total2 = response.data;
        },
        (error) => {}
      );
    },

    get_monthly() {
      var self = this;
      let formData = new FormData();
      formData.append("type", self.monthly_select.table);
      return dashboardService.get_monthly(formData).then(
        (response) => {
          console.log(response);
          self.chart1_data = [];
          self.chart1_months = [];
          response.data.forEach((element) => {
            self.chart1_data.push(element.count);
            self.chart1_months.push(element.months);
          });
          document.getElementById("salesChart").remove();
          document
            .getElementById("salesChartDev")
            .insertAdjacentHTML(
              "beforeend",
              '<canvas :height="170" id="salesChart"></canvas>'
            );
          self.monthly_order();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    get_chart_by() {
      var self = this;
      let formData = new FormData();
      formData.append("type", self.selected_chart_by.table);
      return dashboardService.get_chart_by(formData).then(
        (response) => {
          self.stages_lable = [];
          self.stages = [];
          response.data.map(function (item) {
            self.stages_lable.push(item.lables);
            self.stages.push(item.count);
          });
          document.getElementById("chart_by").remove();
          document
            .getElementById("chartbyDev")
            .insertAdjacentHTML(
              "beforeend",
              '<canvas id="chart_by" class="chart-canvas" height="300"></canvas>'
            );

          self.order_stages();
        },
        (error) => {
          console.log(error);
        }
      );
    },

    get_ticket_chart() {
      var self = this;
      return dashboardService.get_ticket_chart().then(
        (response) => {
          self.loading = false;
          // self.tickets_lable =[];
          // self.tickets=[];
          self.tickets_lable = response.data.tickets.lables;
          self.tickets = response.data.tickets.data;
          self.get_tickets();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    get_applicants() {
      var self = this;
      return dashboardService.get_customers().then((response) => {
        response.data.users.map((a) => {
          var tmp = {};
          tmp["name"] = a.user;
          tmp["id"] = a.id;
          tmp["company"] = a.company ? a.company : a.user;
          self.applicants.push(tmp);
        });
      });
      (error) => {
        console.log(error);
      };
    },
    get_chart_inquiries() {
      var self = this;
      return dashboardService.repeatedInquiries().then(
        (response) => {
          response.data.map(function (item) {
            var tmp = {};
            tmp["name"] = item.inquery_lang;
            tmp["id"] = item.id;
            self.total_inquiry.push(tmp);
          });
          self.selected_inquiry = self.total_inquiry[0];
        },
        (error) => {
          console.log(error);
        }
      );
    },
    redraw() {
      document.getElementById("compair").remove();
      document
        .getElementById("chartLine")
        .insertAdjacentHTML(
          "beforeend",
          '<canvas id="compair" height="100"></canvas>'
        );
      this.get_comapir_chart_data();
      // this.compair_chart();
    },
    compair_chart() {
      var self = this;
      var crt = new Chart(document.getElementById("compair"), {
        type: "line",
        data: {
          labels: self.labels,
          // datasets: [{
          //     label: '# of Votes',
          //     data: self.chart_data,
          //     backgroundColor:'#047a6f',
          //     borderWidth: 1
          // }]

          datasets: self.datasets,
          // [{
          //   label: 'user3 user3',
          //   data: [
          //     12,
          //     0,
          //     0,
          //     0,
          //     0,
          //     0,
          //     0,
          //     0,
          //     0,
          //     0,
          //     0,
          //     0
          //   ],
          //   backgroundColor: '#047a6f',
          //   borderWidth: 1
          // }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },

        // data: {
        //   labels: ['a', 'b', 's', 'f', 'd'],

        //   datasets: [{
        //     label: '# of Votes',
        //     data: [1, 4, 1, 55, 1],
        //     backgroundColor: '#047a6f',
        //     borderWidth: 1
        //   }]

        // },
        // options: {
        //   responsive: true,
        //   plugins: {
        //     legend: {
        //       position: 'top',
        //     },
        //     title: {
        //       display: true,
        //       text: 'Compair Chart'
        //     }
        //   }
        // },
      });
    },
    print() {
      window.print();
    },
    get_comapir_chart_data() {
      var self = this;
      console.log(self.selected_inquiry);
      // let inquiries_ids = [];
      // self.selected_inquiry.map((a) => inquiries_ids.push(a.id));
      self.labels = [];
      self.datasets = [];

      let formData = new FormData();
      let type;
      if (self.radioCheck == 0) {
        type = "monthly";
      } else if (self.radioCheck == 1) {
        type = "yearly";
      } else {
        type = "quarterly";
      }
      console.log("ammar");
      console.log(self.applicant1);
      console.log(self.applicant2);
      formData.append("type", type);
      formData.append("user1", self.applicant1.id);
      formData.append("user2", self.applicant2.id);
      // inquiries_ids.forEach((item) => formData.append("inquiries[]", item));
      // formData.append("user_id", this.$route.params.id);
      formData.append("year", self.selected_year);
      formData.append("inquiry_id", self.selected_inquiry.id);

      return dashboardService.get_comapir_chart_data(formData).then(
        (response) => {
          this.message = {
            msg: response.data.message,
            type: "Success",
          };
          console.log("response.data.datasets");
          console.log(response.data.datasets);
          self.labels = response.data.labels;
          self.datasets = response.data.datasets;
          // this.inquiry_chart();
          this.compair_chart();
        },
        (error) => {
          console.log(error);
          this.message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
        }
      );
    },
  },
  mounted() {
    this.loading = true;
    this.get_total();
    this.get_total2();
    this.get_monthly();
    this.get_status_months();
    this.get_chart_by();
    this.get_ticket_chart();
    // this.get_data();
    document.title = this.$i18n.t("sidebar.Dashboard");
    this.compair_chart();
    this.get_applicants();
    this.get_chart_inquiries();
    let currentYear = new Date().getFullYear();
    let earliestYear = 1970;
    this.years.push(currentYear);
    this.selected_year = currentYear;
    while (currentYear >= earliestYear) {
      currentYear -= 1;
      this.years.push(currentYear);
    }
  },
};
</script>
<style scoped>
.select_by {
  display: inline-flex;
  width: 50%;
}

.fix-hight {
  min-height: 395px;
}

#chartLine {
  min-height: 342px;
}
</style>