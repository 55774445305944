class DashboardService {
    get_data() {
        return axios.get('dashboard/reports')
    };
    get_total(data) {
        return axios.post('dashboard/cards', data)
    };
    get_monthly(data) {
        return axios.post('dashboard/monthlyChart', data)
    };
    get_monthly_status(data) {
        return axios.post('dashboard/applicationStatusChart', data)
    }
    get_chart_by(data) {
        return axios.post('dashboard/applicationChart', data)
    }
    get_ticket_chart() {
        return axios.post('dashboard/ticketChart')
    }
    get_customers() {
        return axios.post('/users/all_customers')
    }
    repeatedInquiries() {
        return axios.get('/repeatable_inquiries/allRepeated')
    }
    get_comapir_chart_data(data) {
        return axios.post('/repeatable_inquiries/CompairChart', data)
    }
}
export default new DashboardService();